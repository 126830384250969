import { createTheme, responsiveFontSizes } from '@material-ui/core/styles'

// A custom theme for this app
export const MainTheme = responsiveFontSizes(
  createTheme({
    typography: {
      fontFamily: '\'Rubik\', sans-serif',
    },
    palette: {
      background: {
        default: '#FFF',
      },
    },
    gofasting: {
      footer: {
        background: '#0D101A',
      },
      steps: {
        item: {
          background: '#0D101A',
          color: '#FFFFFF',
        },
        line: {
          color: '#0D101A',
        },
      },
    },
  }),
)

export default MainTheme
