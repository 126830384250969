import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import Button from './Button'
import Logo from './Logo'

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
  },
  container: {
    overflow: 'hidden',
  },
  footer: {
    marginTop: theme.spacing(10),
    width: '180%',
    marginLeft: '-40%',
    borderRadius: '100% 100% 50% 50%',
    minHeight: theme.spacing(50),
    background: 'linear-gradient(180deg, #C620DB 0%, #FF2B5B 100%)',
  },
  content: {
    marginTop: theme.spacing(40) * -1,
  },
  button: {
    paddingTop: theme.spacing(3),
  },
  textContainer: {
    marginTop: theme.spacing(10),
  },
  text: {
    textAlign: 'center',
    fontSize: '14px',
    color: 'rgba(255, 255, 255, 0.7)',
  },
  image: {
    width: '100%',
    height: 'auto',
  },
}))

function Footer({ link }) {
  const classes = useStyles()

  return (
    <Grid container justifyContent="center" className={classes.root}>
      <Grid item xs={12}>
        <Grid container className={classes.wave}>
          <Grid item xs={12}>
            <div className={classes.footer} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid
          container
          justifyContent="center"
          className={classes.content}
        >
          <Grid item xs={12}>
            <Logo link={link} />
          </Grid>
          <Grid container justifyContent="center" className={classes.button}>
            <Grid
              item
              xs={10}
              sm={8}
              md={6}
            >
              <Button href={link}>Try For Free</Button>
            </Grid>
          </Grid>

          <Grid item xs={12} className={classes.textContainer}>
            <Typography className={classes.text}>
              ©
              {' '}
              {`${new Date().getFullYear()}`}
              {' '}
              by Azur Apps
            </Typography>
          </Grid>
        </Grid>
      </Grid>

    </Grid>
  )
}

Footer.propTypes = {
  link: PropTypes.string.isRequired,
}

export default Footer
